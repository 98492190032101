import { ComponentProps, FC } from "react";
import { createRoot,  } from "react-dom/client";
import DOMPurify from 'dompurify';
import React from "react";
import { flushSync } from "react-dom";

export const renderToString = (component: FC, props?: ComponentProps<FC>) => {
  // Create a temporary DOM element to render the component into
  const tempDiv = document.createElement('div');

  // Create a root using React 18's createRoot
  const root = createRoot(tempDiv);

  // Use flushSync to render the component synchronously
  flushSync(() => {
    root.render(React.createElement(component, props));
  });

  // Extract the innerHTML from the tempDiv after rendering
  const innerHTML = tempDiv.innerHTML;

  // Clean up: unmount the component and remove the tempDiv
  root.unmount();
  tempDiv.remove();

  // Return the innerHTML string
  return DOMPurify.sanitize(innerHTML);
};
