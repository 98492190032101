import { FC } from 'react';
import { createRoot } from 'react-dom/client';
import L from 'leaflet';
import { createControlComponent as createLeafletControl } from '@react-leaflet/core';

const createControlComponent = <T extends {}>(Component: FC<T>, options?: L.ControlOptions) => {
  const div = L.DomUtil.create('div');
  const root = createRoot(div);
  return (props: T) => {
    const ControlComponent = createLeafletControl(() => {
      
      const CustomControl = L.Control.extend({
        onAdd: () => {
          root.render(<Component {...props} />);
          return div;
        },
        onRemove: () => {},
      });
      return new CustomControl(options);
    });

    return <ControlComponent />;
  };
};

export default createControlComponent;
