import { FC, useMemo } from "react";
import { Marker, Tooltip as LeafletTooltip } from "react-leaflet";
import type { IPortMarker, ITooltipProps } from "./types";

import styled from '@emotion/styled'
import L from "leaflet";
import { renderToString } from "../utils/renderToString";
import { Typography } from "@mui/material";
import AnchorIcon from '@mui/icons-material/Anchor';

const Tooltip = ({ isSelected, isDisabled, ...props }: ITooltipProps) => (
  <LeafletTooltip {...props} />
);

const PortTitle = styled(Typography)({
  color: "black",
  fontWeight: 700,
  textAlign: "center"
});

const StyledAnchorIcon = styled(AnchorIcon)({
  color: "white",
  backgroundColor: "#66aaff",   // Default background color
  width: 16,
  height: 16,
  padding: 6,
  borderRadius: "50%",
  opacity: 1,
  transition: "background-color 0.3s ease, transform 0.3s ease", // Smooth transition on hover

  // Hover effect
  "&:hover": {
    backgroundColor: "#1a73e8",  // Change background color on hover
    transform: "scale(1.1)",     // Slightly scale the icon on hover for emphasis
    cursor: "pointer",           // Show pointer cursor on hover
  },
});

const DisabledAnchorIcon = styled(AnchorIcon)({
  color: "white",
  backgroundColor: "#BDBDBD",
  width: 16,
  height: 16,
  padding: 6,
  borderRadius: "50%",
  cursor: "not-allowed"
});

const SelectedAnchorIcon = styled(AnchorIcon)({
  color: "white",
  backgroundColor: "#3388ff",
  width: 16,
  height: 16,
  padding: 6,
  borderRadius: "50%",
  opacity: 1,

  transition: "background-color 0.3s ease, transform 0.3s ease", // Smooth transition on hover

  // Hover effect
  "&:hover": {
    backgroundColor: "#1a73e8",  // Change background color on hover
    transform: "scale(1.1)",     // Slightly scale the icon on hover for emphasis
    cursor: "pointer",           // Show pointer cursor on hover
  },
});

const markerIcon = renderToString(StyledAnchorIcon, {});

const disabledMarkerIcon = renderToString(DisabledAnchorIcon, {});

const selectedMarkerIcon = renderToString(SelectedAnchorIcon, {});

const PortMarker: FC<IPortMarker> = ({ 
  port, 
  isSelected, 
  onPortClick, 
  onPortMouseEnter, 
  onPortMouseLeave, 
  isDisabled 
}) => {
  const icon = useMemo(() => {
    let icon = markerIcon;
    if(isSelected) {
      icon = selectedMarkerIcon; 
    }

    if(isDisabled) {
      icon = disabledMarkerIcon; 
    }

    return L.divIcon({
      html: icon,
      className: '',
      iconAnchor: [15, 15], 
    })
  }, [port.name, isDisabled, isSelected]);
  
  if(!port.coordinates) {
    return null
  }

  return (
    <Marker 
      position={port.coordinates}
      eventHandlers={{ 
        click: onPortClick,
        mouseover: onPortMouseEnter,
        mouseout: onPortMouseLeave,
      }}
      icon={icon}
      interactive
    >
      <Tooltip
        direction="center"
        offset={[0,-28]}
        opacity={0.9}
        permanent
      >
        <PortTitle>{port.name}</PortTitle>
      </Tooltip>
    </Marker>
  )
}

export default PortMarker;