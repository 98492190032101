import { useState } from "react";
import tvintransus from "../api/tvintransus";
import { ILine } from "../components/types";
import { mapTvintransusFerryLine } from "../utils/mappers";

interface IFetchFerryLinesInput {
  clientId: string;
  countryId: string;
}

const useFerryLines = () => {
  const [ferryLines, setFerryLines] = useState<ILine[]>([]);

  const fetchFerryLines = async ({ clientId, countryId }: IFetchFerryLinesInput) => {
    try {
      const response = await tvintransus.getFerryLines({ clientId, countryId });
      const mappedFerryLines = response.rows.map(mapTvintransusFerryLine);
      setFerryLines(mappedFerryLines);
    } catch (error) {
      console.error(error);
    }
  }

  const clearFerryLines = () => {
    setFerryLines([]);
  }

  return {
    clearFerryLines,
    fetchFerryLines,
    ferryLines
  }
}


export default useFerryLines;