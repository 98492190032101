
import axios from "axios";
import { Tvintransus } from "./types";
import { getAccessToken, setAccessToken } from "../utils/helpers";

const BASE_URL = process.env.REACT_APP_TVINTRANSUS_API_URL || 'http://localhost:8000/proxy';

const getDefaultConfig = () => {
  return {
    headers: {
      "authorization": getAccessToken(),
    }
  }
}

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.response.use( 
  response => response,
  error => {
    if(error.response.status === 401) {
      setAccessToken("");
      window.location.assign("/");
    }

    return error;
  }
);

const login = async (params: { username: string; password: string; }) => {
  return (await axiosInstance.post<{ accessToken: string }>("/auth/login", params)).data?.accessToken;
}

const getPorts = async () => {
  return (await axiosInstance.get<Tvintransus.IPaginatedResult<Tvintransus.IPort>>("/port/json", getDefaultConfig())).data;
}

const getClients = async () => {
  return (await axiosInstance.get<Tvintransus.IPaginatedResult<Tvintransus.IClient>>("/client/json", getDefaultConfig())).data;
}

type TGetCountriesByClientId = ({ clientId }: { clientId: string }) => Promise<Tvintransus.IPaginatedResult<Tvintransus.ICountry>>

const getCountriesByClientId: TGetCountriesByClientId = async ({ clientId }) => {
  return (await axiosInstance.get(`/reservation/${clientId}/countries`, getDefaultConfig())).data;
}

type TGetFerryLines = ({ clientId, countryId }: { clientId: string, countryId: string }) => Promise<Tvintransus.IPaginatedResult<Tvintransus.IFerryLine>>

const getFerryLines: TGetFerryLines = async ({ clientId, countryId }) => {
  return (await axiosInstance.get(`/reservation/${clientId}/ferrylines/${countryId}`, getDefaultConfig())).data;
}

export default {
  getPorts,
  getClients,
  getCountriesByClientId,
  getFerryLines,
  login
}