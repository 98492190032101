import { Polyline, Tooltip } from "react-leaflet";
import type { IPortLineProps } from "./types";

const PortLine = (props: IPortLineProps) => {
  const { positions, onClick, isDashed, tooltip } = props;
  const dashArray = isDashed ? [10, 10] : []

  return (
    <>
      <Polyline
        positions={positions}
        eventHandlers={{
          click: onClick,
        }}
        pathOptions={{
          color: 'transparent',
          weight: 25,
          interactive: true,
        }}
      >
        <Polyline
          dashArray={dashArray}
          positions={positions}
          eventHandlers={{
            click: onClick,
          }}
        />
        {tooltip &&
          <Tooltip sticky>{tooltip}</Tooltip>
        }
      </Polyline>
    </>
  )
}

export default PortLine