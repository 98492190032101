export default {
  "1": "51.3300,3.2077",     // Zeebrugge, BE
  "2": "51.2213,2.9286",     // Ostend, BE
  "3": "53.9500,10.8677",    // Travemunde, DE
  "4": "54.0833,12.1333",    // Rostock, DE
  "5": "53.8697,10.6866",    // Lubeck, DE
  "6": "54.5127,13.6432",    // Sassnitz, DE
  "7": "54.3213,10.1349",    // Kiel, DE
  "8": "54.5010,11.2233",    // Puttgarden, DE
  "9": "53.5333,8.5833",     // Bremerhaven, DE
  "10": "53.8701,8.6998",    // Cuxhaven, DE
  "11": "57.4333,10.5333",   // Frederikshavn, DK
  "12": "55.4673,8.4484",    // Esbjerg, DK
  "13": "55.6761,12.5683",   // Copenhagen, DK
  "14": "55.4038,10.4024",   // Odense, DK
  "15": "55.5632,9.7506",    // Fredericia, DK
  "16": "54.6513,11.3567",   // Rodby, DK
  "17": "54.5759,11.9410",   // Gedser, DK
  "18": "56.0337,12.6122",   // Helsingor, DK
  "19": "55.0391,10.3300",   // Spodsjberg, DK
  "20": "55.4607,10.2544",   // Tars, DK
  "21": "55.0938,10.3304",   // Bojden, DK
  "22": "55.0481,9.7403",    // Fynshav, DK
  "23": "55.1052,14.7068",   // Ronne, DK
  "24": "55.4561,12.1821",   // Koge, DK
  "25": "55.4028,8.4066",    // Fano, DK
  "26": "55.8473,10.6141",   // Ballen, DK
  "27": "55.6769,11.0892",   // Kalundborg, DK
  "28": "58.1467,7.9956",    // Kristiansand, NO
  "29": "59.4370,24.7535",   // Tallinn, EE
  "30": "59.3567,24.0533",   // Paldiski, EE
  "31": "41.3851,2.1734",    // Barcelona, ES
  "32": "60.1695,24.9354",   // Helsinki, FI
  "33": "59.8311,22.9707",   // Hanko, FI
  "34": "60.1029,19.9313",   // Langnas, FI
  "35": "60.0973,19.9344",   // Mariehamn, FI
  "36": "60.4518,22.2666",   // Turku, FI
  "37": "60.4669,22.0245",   // Naantali, FI
  "38": "60.4660,26.9458",   // Kotka, FI
  "39": "50.9581,1.8506",    // Calais, FR
  "40": "51.0344,2.3768",    // Dunkerque, FR
  "41": "49.9221,1.0788",    // Dieppe, FR
  "42": "49.4944,0.1079",    // Le Havre, FR
  "43": "49.6434,-1.6225",   // Cherbourg, FR
  "44": "48.6361,-2.0226",   // St Malo, FR
  "45": "51.1279,1.3134",    // Dover, GB
  "46": "54.6198,-1.0629",   // Teesport, GB
  "47": "53.7457,-0.3367",   // Hull, GB
  "48": "54.9738,-5.0111",   // Cairnryan, GB
  "49": "51.4645,0.3562",    // Tilbury, GB
  "50": "55.5444,-4.6645",   // Troon, GB
  "51": "53.3059,-4.6309",   // Holyhead, GB
  "52": "50.8179,-1.0912",   // Portsmouth, GB
  "53": "50.7939,0.0488",    // Newhaven, GB
  "54": "50.7192,-1.9823",   // Poole, GB
  "55": "53.6973,-0.2845",   // Killingholme, GB
  "56": "51.4817,0.2391",    // Purfleet, GB
  "57": "52.0568,1.1482",    // Ipswich, GB
  "58": "54.9037,-5.0264",   // Stranraer, GB
  "59": "52.0098,-4.9814",   // Fishguard, GB
  "60": "51.9442,1.2883",    // Harwich, GB
  "61": "53.4084,-2.9916",   // Liverpool, GB
  "62": "51.6728,-4.9120",   // Pembroke, GB
  "63": "53.6116,-0.1641",   // Immingham, GB
  "64": "51.3341,1.4183",    // Ramsgate, GB
  "65": "51.0808,1.1740",    // Folkestone, GB
  "66": "51.9534,1.3036",    // Felixstowe, GB
  "67": "54.9783,-1.6174",   // Newcastle upon Tyne, GB
  "68": "39.6649,20.8522",   // Igoumenitsa, GR
  "69": "38.2444,21.7346",   // Patra, GR
  "70": "54.8502,-5.8103",   // Larne, GB
  "71": "53.3498,-6.2603",   // Dublin, IE
  "72": "54.6079,-5.9264",   // Belfast, IE
  "73": "52.3965,-6.3810",   // Rosslare, IE
  "74": "44.3084,8.4796",    // Savona, IT
  "75": "42.1007,11.7692",   // Civitavecchia, IT
  "76": "40.9231,9.4992",    // Olbia, IT
  "77": "42.9300,10.5168",   // Piombino, IT
  "78": "43.5513,10.3027",   // Livorno, IT
  "79": "45.1252,6.6981",    // Bardonecchia, IT
  "80": "43.6169,13.5167",   // Ancona, IT
  "81": "41.1256,16.8666",   // Bari, IT
  "82": "55.7107,21.1317",   // Klaipeda, LT
  "83": "57.3897,21.5694",   // Ventspils, LV
  "84": "56.5120,21.0096",   // Liepaja, LV
  "85": "56.9496,24.1052",   // Riga, LV
  "86": "51.9225,4.4792",    // Rotterdam, NL
  "87": "51.9748,4.1330",    // Hoek van Holland, NL
  "88": "52.4610,4.5883",    // Ijmuiden, NL
  "89": "59.9115,10.7579",   // Oslo, NO
  "90": "57.5959,9.9593",    // Hirtshals, DK
  "91": "54.5189,18.5305",   // Gdynia, PL
  "92": "53.9108,14.2455",   // Swinoujscie, PL
  "93": "41.1857,-8.7057",   // Leixões, PT
  "94": "59.9343,30.3351",   // Saint Petersburg, RU
  "95": "57.7089,11.9746",   // Gothenburg, SE
  "96": "56.1612,15.5869",   // Karlskrona, SE
  "97": "59.3293,18.0686",   // Stockholm, SE
  "98": "58.9043,17.9484",   // Nynashamn, SE
  "99": "59.7500,19.0833",   // Kapellskar, SE
  "100": "56.1684,14.8624",  // Karlshamn, SE
  "101": "55.6049,13.0007",  // Malmo, SE
  "102": "55.3731,13.1569",  // Trelleborg, SE
  "103": "55.4295,13.8209",  // Ystad, SE
  "104": "60.1695,24.9354",  // Helsinki, FI
  "105": "56.0467,12.6945",  // Helsingborg, SE
  "106": "57.6400,18.2961",  // Visby, SE
  "107": "56.4064,10.9236",  // Grenaa, DK
  "108": "57.1054,12.2508",  // Varberg, SE
  "109": "59.3888,27.7654",  // Sillamäe, EE
  "110": "59.7498,28.3667",  // Ust-Luga, RU
  "111": "45.7884,6.9787",   // Courmayeur, IT
  "112": "45.9237,6.8694",   // Chamonix, FR
  "113": "45.7640,4.8357",   // Lyon, FR
  "114": "43.7102,7.2620",   // Nice, FR
  "115": "45.2066,6.6234",   // Modane, FR
  "116": "46.2044,6.1432",   // Geneva, CH
  "117": "47.3769,8.5417",   // Zurich, CH
  "118": "46.8182,8.2275",   // Switzerland, CH
  "119": "50.8503,4.3517",   // Brussels, BE
  "120": "60.3929,5.3242",   // Bergen, NO
  "121": "42.6977,9.4508",   // Bastia, FR
  "122": "51.5098,-0.1180",  // London, GB
  "123": "41.8719,12.5674",  // Italy, IT
  "124": "38.2457,21.7345",  // Patras, GR
  "125": "51.5098,-0.1180",  // London, GB
  "126": "51.4460,0.2164",   // Dartford, GB
  "127": "59.0019,9.7395",   // Langesund, NO
  "128": "50.8487,-1.1729",  // Fishbourne, GB
  "129": "59.0534,10.0354",  // Larvik, NO
  "130": "38.1938,15.5540",  // Messina, IT
  "131": "38.2176,15.6440",  // Villa San Giovanni, IT
  "132": "45.4441,8.6224",   // Novara, IT
  "133": "47.9990,7.8421",   // Freiburg, DE
  "134": "51.2194,4.4025",   // Antwerp, BE
  "135": "58.9377,11.1716",  // Strömstad, SE
  "136": "59.1304,10.2248",  // Sandefjordas, NO
  "137": "47.4833,12.2007",  // Wörgl, AT
  "138": "44.4056,8.9463",   // Genova, IT
  "139": "45.4845,9.1916",   // Milan, IT
  "140": "63.8258,20.2630",  // Umeå, SE
  "141": "63.0960,21.6158",  // Vaasa, FI
  "142": "38.1157,13.3615",  // Palermo, IT
  "143": "40.8522,14.2681",  // Naples, IT
  "144": "58.5735,23.5166",  // Virtsu, EE
  "145": "58.5835,23.3644",  // Kuivastu, EE
  "146": "40.6293,17.9366",  // Brindisi, IT
  "147": "39.0200,1.4821",   // Ibiza, ES
  "148": "41.3851,2.1734",   // Barcelona (Barselona), ES
  "149": "52.3702,4.8952",   // Netherlands
  "150": "37.7749,-122.4194", // San Francisco, US
  "151": "53.5511,9.9937",   // Hamburg, DE
  "152": "50.1109,8.6821",   // Frankfurt, DE
  "153": "56.4608,10.0400",  // Ballen, DK
  "154": "54.6872,25.2797",  // Vilnius, LT
  "155": "39.8413,3.1219",   // Alcudia, ES
  "156": "60.1695,24.9354",  // Helsinki (Vuosaari), FI
  "157": "58.9101,23.4344",  // Rohuküla, EE
  "158": "59.4460,24.7454",  // Hentermaa, EE
  "159": "56.1629,10.2039",  // Aarhus, DK
  "160": "55.9507,10.7201",  // Odden, DK
  "161": "58.9701,5.7331",   // Stavanger, NO
  "162": "59.4859,24.7267",  // Muuga, EE
  "163": "43.1242,5.9280",   // Toulon, FR
  "164": "41.9192,8.7386",   // Ajaccio, FR
  "165": "60.8000,21.4086",  // Uusikaupunki, FI
  "166": "59.4370,24.7535",  // Estonia, EE
  "167": "36.5271,-6.2886",  // Cadiz, ES
  "168": "28.1235,-15.4363", // Las Palmas, ES
  "169": "43.5081,16.4402",  // Split, HR
  "170": "40.6824,14.7681",  // Salerno, IT
  "171": "49.1829,-0.3707",  // Caen, FR
  "172": "43.4623,-3.8099",  // Santander, ES
  "173": "59.8677,30.2816",  // Bronka, RU
  "174": "54.3520,18.6466",  // Gdansk, PL
  "175": "49.4482,-2.5895",  // Guernsey, GB
  "176": "51.1657,10.4515",  // Germany
  "177": "58.9645,-3.2960",  // Stromness, GB
  "178": "58.6126,-3.5551",  // Scrabster, GB
  "179": "60.0000,90.0000",  // Placeholder for Russia
  "180": "54.6872,25.2797",  // Lithuania (LT)
  "181": "58.9753,-2.9576",  // Kirkwall, GB
  "182": "59.2418,-3.1416",  // Rapness, GB
  "183": "57.1497,-2.0943",  // Aberdeen, GB
  "184": "49.2144,-2.1312",  // Jersey, GB
  "185": "57.5825,-6.3818",  // Uig, GB
  "186": "57.4751,-7.3652",  // Lochmaddy, GB
  "187": "57.2645,15.9906",  // Oskarshamn, SE
  "188": "46.0667,11.1167",  // Trento, IT
  "189": "41.3231,19.4414",  // Durres, AL
  "190": "60.1557,-1.1455",  // Lerwick, GB
  "191": "39.5696,2.6502",   // Palma de Mallorca, ES
  "192": "39.4699,-0.3763",  // Valencia, ES
  "193": "58.6463,-3.1165",  // Gills Bay, GB
  "194": "58.8133,-3.1010",  // St Margarets Hope, GB
  "195": "55.8887,-4.8779",  // Wemyss Bay, GB
  "196": "55.8341,-5.0578",  // Rothesay, GB
  "197": "39.2238,9.1217",   // Cagliari, IT
  "198": "55.7023,21.1317",  // Klaipeda TT, LT
  "199": "56.6745,12.8578",  // Halmstad, SE
  "200": "45.4371,12.3327",  // Venice, IT
  "201": "52.5200,13.4050",  // Berlin, DE
  "202": "56.2054,11.6688",  // Saelvig, DK
  "203": "55.9000,9.8667",   // Hou, DK
  "204": "35.9375,14.3754",  // Malta
  "205": "36.1408,-5.3536",  // Algeciras, ES
  "206": "35.7595,-5.8339",  // Tanger, MA
  "207": "40.8398,8.4037",   // Porto Torres, IT
  "208": "58.6704,17.1040",  // Oxelösund, SE
  "209": "43.2965,5.3698",   // Marseille, FR
  "210": "54.7743,8.8346",   // Niebull, DE
  "211": "54.9141,8.3178",   // Sylt, DE
  "212": "54.0339,-2.9137",  // Heysham, GB
  "213": "43.2630,-2.9350",  // Bilbao, ES
  "214": "37.0856,25.1462",  // Paros, GR
  "215": "37.9474,23.6412",  // Piraeus, GR
  "216": "35.5116,24.0136",  // Chania, GR
  "217": "46.4837,30.7326",  // Chornomorsk, UA
  "218": "41.6168,41.6367",  // Batumi, GE
  "219": "40.1424,26.4064",  // Canakkale, TR
  "220": "45.2661,19.8335",  // Novi Sad, RS
  "221": "41.0699,30.7932",  // Karasu, TR
  "222": "52.1326,5.2913",   // Netherlands
  "223": "42.1465,41.6706",  // Poti, GE
  "224": "51.8985,-8.4756",  // Cork, IRL
  "225": "51.4402,0.7502",   // Sheerness, GB
  "226": "48.7250,-3.9688",  // Roscoff, FR
  "227": "53.4475,6.8345",   // Eemshaven, NL
  "228": "53.5898,6.6907",   // Borkum, DE
  "229": "55.9970,-6.0157",  // Tarbert, GB
  "230": "58.2157,-6.3888",  // Stornoway, GB
  "231": "57.8942,-5.1590",  // Ullapool, GB
  "232": "48.8566,2.3522",   // France
  "233": "48.8584,2.2945",   // EU
  "234": "57.1561,-7.3082",  // Lochboisdale, GB
  "235": "56.9628,-5.8284",  // Oban, GB
  "236": "57.0047,-5.8280",  // Mallaig, GB
  "237": "60.2008,19.5661",  // Eckero, FI
  "238": "60.1017,18.8414",  // Grisslehamn, SE
  "239": "54.9114,8.3143",   // List(Sylt), DE
  "240": "51.9244,4.4777",   // Rotterdam, NL
  "241": "54.8384,8.3064",   // List Sylt, DE
  "242": "54.9461,8.4388",   // Havneby, DK
  "243": "36.7325,14.8548",  // Pozzallo, IT
  "244": "37.4467,25.3753",  // Mykonos, GR
  "245": "56.9742,-7.4936",  // Castlebay, GB
  "246": "50.7577,-1.5411",  // Lymington, GB
  "247": "50.7041,-1.5032",  // Yarmouth, GB
  "248": "37.7151,24.0660",  // Lavrio, GR
  "249": "34.7071,33.0226",  // Limassol, CY
  "250": "28.4636,-16.2518", // Santa Cruz de Tenerife, ES
  "251": "40.9946,9.6215",   // Golfo Aranci, IT
  "252": "39.9323,9.7057",   // Arbatax, IT
  "253": "42.5317,2.8225",   // Le Boulou, FR
  "254": "49.5156,6.0856",   // Bettembourg, LU
  "255": "51.4323,6.7668"    // Duisburg, DE
} as Record<string, string>;
