import { Tvintransus } from "../api/types";
import { CountryCode, ICoordinates, ICountry, ILine, IPort } from "../components/types";
import locationDegreesMapById from "../data/locationDegreesMapById";

const mapLocationDegreesToCoordinates = (locationDegrees: string): ICoordinates | null => {
  if (locationDegrees.trim().length === 0) {
    return null;
  }

  const [lat, lng] = locationDegrees.split(",");

  return {
    lat: Number(lat),
    lng: Number(lng)
  }
}

export const mapTvintranusPort = (port: Tvintransus.IPort): IPort | null => {
  const coordinates = mapLocationDegreesToCoordinates(locationDegreesMapById[port.id] ?? '');

  return {
    id: port.id,
    name: port.code,
    coordinates,
    country: port.country as CountryCode,
  }
}

export const mapTvintransusFerryLine = (ferryLine: Tvintransus.IFerryLine) : ILine => {
  const [fromId, toId] = ferryLine.id.split("_");

  return {
    id: ferryLine.id,
    fromId: Number(fromId),
    toId: Number(toId),
    name: ferryLine.text,
  }
}

export const mapTvintransusCountry = (country: Tvintransus.ICountry) : ICountry => ({
  id: country.id,
  code: country.text,
});