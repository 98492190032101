import { useState } from "react";
import tvintransus from "../api/tvintransus";
import { IPort } from "../components/types";
import { mapTvintranusPort } from "../utils/mappers";

const usePorts = () => {
  const [ports, setPorts] = useState<IPort[]>([]);

  const fetchPorts = async () => {
    try {
      const response = await tvintransus.getPorts();
      const tvintransusPorts = response.rows;

      const ports = tvintransusPorts.map(mapTvintranusPort).filter(Boolean) as IPort[];

      setPorts(ports);
    } catch (error) {
      console.error(error);
    }
  }

  return {
    fetchPorts,
    ports
  }

}


export default usePorts;