import type { CountryCode } from '../../components/types';
import DE from './de.json';
import DK from './dk.json';
import LV from './lv.json';
import LT from './lt.json';
import EE from './ee.json';
import FI from './fi.json';
import PL from './pl.json';
import RU from './ru.json';
import SE from './se.json';
import MT from './mt.json';
import NL from './nl.json';
import NO from './no.json';
import GB from './gb.json';
import GE from './ge.json';
import IT from './it.json';
import MA from './ma.json';
import FR from './fr.json';
import IRL from './irl.json';
import IS from './is.json';
import TR from './tr.json';
import ES from './es.json';
import CY from './cy.json';
import AL from './al.json';
import UA from './ua.json';
import AT from './at.json';
import BE from './be.json';
import PT from './pt.json';
import RO from './ro.json';
import HR from './hr.json';

export default {
  LV,
  LT,
  EE,
  DE,
  DK,
  FI,
  PL,
  RU,
  SE,
  MT,
  NL,
  NO,
  GB,
  GE,
  IT,
  MA,
  FR,
  IRL,
  IS,
  TR,
  ES,
  CY,
  AL,
  UA,
  AT,
  BE,
  PT,
  RO,
  HR,
} as Record<CountryCode, any>