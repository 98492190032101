import { LayerGroup } from 'react-leaflet';
import { FC, useMemo, useState } from 'react';
import type { ILine, IPort } from './types';
import PortMarker from './PortMarker';
import PortLine from './PortLine';
import _ from "lodash"

interface IPortMarkersWithLinesProps {
  selectableFromPorts: IPort[];
  selectableToPorts: IPort[];
  ferryLines: ILine[];
  selectedFromPort: IPort | null;
  selectedToPort: IPort | null;
  onToPortClick: any;
  onFromPortClick: any;
}

const PortMarkersWithLines: FC<IPortMarkersWithLinesProps> = ({
  selectableFromPorts,
  selectableToPorts,
  ferryLines,
  selectedFromPort,
  selectedToPort,

  onToPortClick,
  onFromPortClick,
}) => {
  const [mouseOverPort, setMouseOverPort] = useState<IPort | null>(null);

  const getFilteredLinesByPort = (port: IPort) =>
    ferryLines
      .filter(line => port.id === line.fromId)
      .map(line => [...selectableFromPorts, ...selectableToPorts].find(port => port.id === line.toId))
      .filter(Boolean) as IPort[];

  const getPortLineTooltip = (port1: IPort, port2: IPort) => `${port1.name} - ${port2.name}`;

  const isPortDisbled = (port: IPort) => {
    const allAvailbleFromPortIds = ferryLines.map(line => line.fromId);
    const selectedPort = selectedFromPort || mouseOverPort;
    if (selectedPort) {
      const allAvailbleToPortIds = ferryLines.filter(line => line.fromId === selectedPort.id).map(line => line.toId);
      return !Boolean([...allAvailbleToPortIds, selectedPort.id].find(id => id === port.id));
    }

    return !Boolean(allAvailbleFromPortIds.find(id => id === port.id));
  }

  const isSelected = (port: IPort) =>
    selectedFromPort?.id === port.id || selectedToPort?.id === port?.id;

  const handlePortMouseEnter = (port: IPort) => () => {
    if (isPortDisbled(port)) {
      return;
    }

    setMouseOverPort(port);
  }

  const handlePortMouseLeave = () => {
    setMouseOverPort(null);
  }

  const handleLineClick = (toPort: IPort) => () => {
    onToPortClick(toPort);
  }

  const handlePortClick = (port: IPort) => () => {
    if (isPortDisbled(port)) {
      return;
    }

    if (!selectedFromPort) {
      return onFromPortClick(port);
    }

    if (port.id === selectedFromPort?.id) {
      return onFromPortClick(null);
    }

    if (port.id === selectedToPort?.id) {
      return onToPortClick(null);
    }

    return onToPortClick(port);
  }

  const dashedLines = useMemo(() => {
    if (!selectedFromPort && mouseOverPort) {
      return getFilteredLinesByPort(mouseOverPort);
    }

    if (selectedFromPort) {
      return getFilteredLinesByPort(selectedFromPort);
    }

    return [];
  }, [mouseOverPort, selectedFromPort, selectableFromPorts]);

  const portToDisplay = useMemo(() =>
    _.uniqBy([...selectableFromPorts, ...selectableToPorts], "id") ?? []
    , [selectableFromPorts, selectableToPorts]);

  return (
    <LayerGroup>
      {portToDisplay.map(port =>
        <PortMarker
          port={port}
          key={`port-${port.id}`}
          onPortClick={handlePortClick(port)}
          onPortMouseEnter={handlePortMouseEnter(port)}
          onPortMouseLeave={handlePortMouseLeave}
          isSelected={isSelected(port)}
          isDisabled={isPortDisbled(port)}
        />
      )}

      {selectedFromPort?.coordinates && selectedToPort?.coordinates &&
        <PortLine
          tooltip={getPortLineTooltip(selectedFromPort, selectedToPort)}
          onClick={handleLineClick(selectedToPort)}
          positions={[
            selectedFromPort.coordinates,
            selectedToPort.coordinates
          ]}
        />
      }

      {(selectedFromPort || mouseOverPort) && dashedLines.map(selectablePort => {
        const port = (selectedFromPort || mouseOverPort) as IPort;

        if(!selectablePort.coordinates || !port.coordinates) {
          return null
        }

        return (
          <PortLine
            tooltip={getPortLineTooltip(port, selectablePort)}
            key={`${port.id}-${selectablePort.id}-dashed`}
            isDashed
            onClick={handleLineClick(selectablePort)}
            positions={[
              port.coordinates,
              selectablePort.coordinates
            ]}
          />
        )
      })}
    </LayerGroup>
  )
}

export default PortMarkersWithLines;