import { MapContainer, TileLayer } from 'react-leaflet';
import PortsMap from './PortsMap';

const TILE_LAYER_URL = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';

const MapView = () => {
  return (
    <MapContainer
      center={{
        lat: 54.1512,
        lng: 12.1006
      }}
      zoom={4.5}
      minZoom={3.5}
      maxZoom={10}
      style={{ height: '100vh' }}
      zoomControl={false}
      zoomSnap={0}
      zoomDelta={0.5}
    > 
      <TileLayer url={TILE_LAYER_URL} opacity={0.7} />
      <PortsMap />
  </MapContainer>
  );
}

export default MapView;
