import { useState } from "react";
import tvintransus from "../api/tvintransus";
import { IClient } from "../components/types";

const useClients = () => {
  const [clients, setClients] = useState<IClient[]>([]);

  const fetchClients = async () => {
    try {
      const response = await tvintransus.getClients();
      setClients(response.rows);

    } catch (error) {
      console.error(error);
    }
  }

  return {
    fetchClients,
    clients
  }

}


export default useClients;