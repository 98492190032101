import { LayerGroup, useMap } from 'react-leaflet';
import { useEffect,  useMemo,  useState } from 'react';
import type { CountryCode, IClient, ICountry, IPort } from './types';
import DestitationForm from './DestitationForm';
import createControlComponent from '../utils/createControlComponent';
import { getCurrentLocation } from '../utils/getCurrentLocation';
import CountriesBorders from './CountriesBorders';
import useCountries from '../hooks/useCountries';
import usePorts from '../hooks/usePorts';
import useClients from '../hooks/useClients';
import useFerryLines from '../hooks/useFerryLines';
import _ from "lodash"
import PortMarkersWithLines from './PortMarkersWithLines';

const DestitationControl = createControlComponent(DestitationForm, { position: "topleft" });

const PortsMap = () => {
  const map = useMap();

  const { fetchClients, clients } = useClients();
  const { fetchPorts, ports } = usePorts();
  const { fetchFerryLines, ferryLines, clearFerryLines } = useFerryLines();
  const { fetchCountries, countries, clearCountries } = useCountries();

  const [selectableFromPorts, setSelectableFromPorts] = useState<IPort[]>([]);
  const [selectableToPorts, setSelectableToPorts] = useState<IPort[]>([]);

  const [selectedFromPort, setSelectedFromPort] = useState<IPort | null>(null);
  const [selectedToPort, setSelectedToPort] = useState<IPort | null>(null);

  const [selectedClient, setSelectedClient] = useState<IClient | null>(null);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | null>(null);

  const setInitialLocation = async () => {
    const location = await getCurrentLocation();

    if (!location) {
      return;
    }
    map.setView(location, map.getZoom())
  }

  const clearSelectedPorts = () => {
    setSelectedFromPort(null);
    setSelectedToPort(null);
  }

  const handleFromPortChange = (port: IPort | null) => {
    if (!port) {
      return clearSelectedPorts();
    }

    setSelectedFromPort(port);
  }

  const handleToPortChange = (port: IPort | null) => {
    if (!port) {
      return setSelectedToPort(null);
    }

    setSelectedToPort(port);
  }

  const handleClientChange = (client: IClient | null) => {
    clearSelectedPorts();
    clearCountries();
    clearFerryLines();
    setSelectedCountry(null);
    setSelectedClient(client);
  }

  const handleCountryChange = (country: ICountry | null) => {
    clearSelectedPorts();
    clearFerryLines();
    if (!country) {
      setSelectedCountry(country);
      return;
    }

    const firstCountryPort = ports.find(port => port.country === country.code);

    setSelectedCountry(country);

    if (!firstCountryPort?.coordinates) {
      return;
    }
    map.setView(firstCountryPort?.coordinates, 7);

  }

  const handleCountryClick = (countryCode: CountryCode) => {
    const firstCountryPort = ports.find(port => port.country === countryCode);
    const country = countries.find(country => country.code === countryCode) ?? null;
    setSelectedCountry(country);

    if (!firstCountryPort?.coordinates) {
      return;
    }

    map.setView(firstCountryPort?.coordinates, 7);
  }

  const toPortOptions = useMemo(() => {
    if(!selectedFromPort) {
      return [];
    }

    return ferryLines.filter(line => selectedFromPort.id === line.fromId)
      .map(line => ports.find(port => port.id === line.toId))
      .filter(Boolean) as IPort[]
  }, [ports, ferryLines, selectedFromPort])

  useEffect(() => {
    if (selectedClient) {
      fetchCountries(selectedClient.id.toString());
    }
  }, [selectedClient]);

  useEffect(() => {
    if (selectedCountry && selectedClient) {
      fetchFerryLines({
        clientId: selectedClient.id.toString(),
        countryId: selectedCountry.id.toString(),
      });
    }
  }, [selectedCountry, selectedClient]);

  useEffect(() => {
    fetchPorts();
    setInitialLocation();
    fetchClients();
  }, []);


  const filterFerryLinePorts = (key: "toId" | "fromId") => _(ports)
  .filter(port => _(ferryLines).map(key).includes(port.id))
  .value();

  useEffect(() => {
    if (ferryLines.length) {
      const filteredFromPorts = filterFerryLinePorts("fromId");
      const filterdToPort = filterFerryLinePorts("toId");
      setSelectableFromPorts(filteredFromPorts);
      setSelectableToPorts(filterdToPort);
    } else {
      setSelectableFromPorts([]);
      setSelectableToPorts([]);
    }
  }, [ferryLines]);

  return (
    <>
      {selectedClient && selectedCountry ?
        <PortMarkersWithLines 
          selectableFromPorts={selectableFromPorts}
          selectableToPorts={selectableToPorts}
          ferryLines={ferryLines}
          selectedFromPort={selectedFromPort}
          selectedToPort={selectedToPort}
          onToPortClick={handleToPortChange}
          onFromPortClick={handleFromPortChange}
        /> :
        <CountriesBorders onCountryClick={handleCountryClick} countries={countries} />
      }

      <LayerGroup>
        <DestitationControl
          fromPort={selectedFromPort}
          toPort={selectedToPort}
          client={selectedClient}
          country={selectedCountry}

          clientOptions={clients}
          countryOptions={countries}
          fromPortOptions={selectableFromPorts}
          toPortOptions={toPortOptions}

          onFromPortChange={handleFromPortChange}
          onCountryChange={handleCountryChange}
          onToPortChange={handleToPortChange}
          onClientChange={handleClientChange}
        />
      </LayerGroup>
    </>
  );
}

export default PortsMap;
