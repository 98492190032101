import { useState } from "react";
import tvintransus from "../api/tvintransus";
import { ICountry } from "../components/types";
import { mapTvintransusCountry } from "../utils/mappers";

const useCountries = () => {
  const [countries, setCountries] = useState<ICountry[]>([]);

  const fetchCountries = async (clientId: string) => {
    try {
      const response = await tvintransus.getCountriesByClientId({ clientId });
      setCountries(response.rows.map(mapTvintransusCountry));
    } catch (error) {
      console.error(error);
    }
  }

  const clearCountries = () => {
    setCountries([]);
  }

  return {
    fetchCountries,
    clearCountries,
    countries
  }

}


export default useCountries;