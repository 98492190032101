import countriesBorders from "../data/countriesBorders";
import { GeoJSON } from 'react-leaflet';
import { CountryCode, ICountry } from "./types";
import { Feature, GeoJsonObject, Geometry } from "geojson";
import { Layer } from "leaflet";

interface CountriesBordersProps {
  onCountryClick: (countryCode: CountryCode) => void;
  countries: ICountry[]
}

const CountriesBorders = ({ onCountryClick, countries }: CountriesBordersProps) => {
  const onEachFeature = (countryCode: CountryCode) => (feature: Feature<Geometry, any>, layer: Layer) => {
    layer.on('click', () => {
      onCountryClick(countryCode);
    });
  };

  return (
    <>
      {
        countries.map(country =>
          <GeoJSON
            data={(countriesBorders as Record<string, GeoJsonObject>)[country.code]}
            onEachFeature={onEachFeature(country.code)}
            key={`country-boundary-${country.code}`}
          />)
      }
    </>
  )
}

export default CountriesBorders;