import { ICoordinates } from "../components/types";

export const getCurrentLocation = (): Promise<ICoordinates | null> =>
  new Promise((resolve) => {
    if (!("geolocation" in navigator)) {
      return resolve(null);
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        return resolve({
          lat: latitude,
          lng: longitude
        });
      },
      (error) => {
        return resolve(null);
      },
      {
        timeout: 5000 // Optional: timeout in milliseconds
      }
    );
  });
