import {
  Autocomplete,
  TextField
} from "@mui/material"
import type { SyntheticEvent } from "react";

interface ISelectInputProps<T> {
  options: T[];
  label: string;
  name: string;
  value?: T | null;

  onChange: (event: SyntheticEvent, value: T | null) => void;
  isDisabled?: boolean


  getOptionLabel?: (option: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
}

const AutoCompleteInput = <T,>(props: ISelectInputProps<T>) => {
  const { name, options, label, value, getOptionLabel, isOptionEqualToValue, onChange, isDisabled } = props;

  const handleIsOptionEqualToValue = (option: T, value: T) => {

    if(isOptionEqualToValue) {
      return isOptionEqualToValue(option, value);
    }

    return option === value;
  }

  return (
    <Autocomplete
      value={value}
      options={options}
      disabled={isDisabled}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      onChange={onChange}
      renderInput={(params) => <TextField {...params} variant="standard" name={name} label={label} />}
    />
  )
}

export default AutoCompleteInput;