import { useState } from "react";
import { Box, Button, Paper, Stack, TextField } from "@mui/material";
import tvintransus from "../api/tvintransus";
import toast from 'react-hot-toast';
import { setAccessToken } from "../utils/helpers";

const DEFAULT_AUTH_ERROR_MESSAGE = "Invalid credentials";

const LoginView = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });
  

  const handleError = (error: string) => {
    toast.error(error);
    setFormData({
      username: formData.username,
      password: '',
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const accessToken = await tvintransus.login(formData);
      
      if(!accessToken) {
        return handleError(DEFAULT_AUTH_ERROR_MESSAGE)
      }
      
      setAccessToken(accessToken);

      window.location.assign("/");
    } catch(error) {
      //@ts-ignore
      const errorMessage = error?.response?.data?.errors[0]?.msg || DEFAULT_AUTH_ERROR_MESSAGE
      return handleError(errorMessage);
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //@ts-ignore
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f5f5f5",
      }}
    >
      <Paper
        elevation={3}
        sx={{
          padding: 3,
          textAlign: "center",
          width: 375,
        }}
      >
        <Stack 
          onSubmit={handleSubmit}
          component="form" 
          sx={{
            gap: 2
          }}
        >
          <TextField onChange={handleChange} name="username" type="text" label="Username" variant="standard" />
          <TextField onChange={handleChange} name="password" type="password" label="Password" variant="standard" />
          <Button type="submit">Login</Button>
        </Stack>
      </Paper>
    </Box>
  );
}

export default LoginView;