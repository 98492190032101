import MapView from './components/MapView';
import LoginView from './components/LoginView';
import { Toaster } from 'react-hot-toast';
import { getAccessToken } from './utils/helpers';
import { useMemo } from 'react';

const App = () => {  
  const accessToken = useMemo(() => getAccessToken(), []);

  return (
    <>
      {accessToken ? <MapView /> : <LoginView />} 
      <Toaster position="bottom-right" />
    </>
  );
}

export default App;
