import type { FC } from "react";
import type { ICountry, IDestitationControlPorps } from "./types"
import { Box, Button, Paper, Typography } from "@mui/material";
import AutoCompleteInput from "./AutoCompleteInput";

const DestitationForm: FC<IDestitationControlPorps> = (props) => {
  const {
    fromPortOptions,
    toPortOptions,
    fromPort,
    toPort,
    clientOptions,
    client,
    countryOptions,
    country,

    onCountryChange,
    onFromPortChange,
    onToPortChange,
    onClientChange,
  } = props;
  
  const getOptionLabel = (option: { name: string }) => option.name;
  const getCountryOptionLabel = (option: { code: string }) => option.code; 

  const handleIsOptionEqualToValue = (option: { id: number }, value: { id: number }) => option.id === value.id;

  const handleSubmit = (event:React.FormEvent) => {
    event.preventDefault(); 
    //TODO redirect to create order form
    alert(JSON.stringify({ fromPort, toPort, client, country }, null, 2));
  }

  return (
    <Paper elevation={10} sx={{ width: 300, p: 2 }} >
      <Typography>Destination: </Typography>
      <Box
        component="form"
        sx={{ '& .MuiTextField-root': { m: 1 } }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        {clientOptions && 
          <AutoCompleteInput
            name="client"
            label="Clients"
            options={clientOptions}
            value={client}
            key={`client-autocomplete-${fromPort?.id}`}
            onChange={(event, client) => onClientChange(client)}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={handleIsOptionEqualToValue}
          />
        }

        <AutoCompleteInput<ICountry>
          name="country"
          label="Country"
          options={countryOptions}
          value={country}
          key={`countries-autocomplete-${country?.id}`}
          onChange={(event, country) => onCountryChange(country)}
          getOptionLabel={getCountryOptionLabel}
          isOptionEqualToValue={handleIsOptionEqualToValue}
        />

        <AutoCompleteInput 
          name="fromPort"
          label="From"
          options={fromPortOptions}
          value={fromPort}
          key={`from-port-autocomplete-${fromPort?.id}`}
          onChange={(event, port) => onFromPortChange(port)}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={handleIsOptionEqualToValue}
        />

        <AutoCompleteInput 
          name="toPort"
          label="To"
          options={toPortOptions}
          value={toPort}
          onChange={(event, port) => onToPortChange(port)}
          getOptionLabel={getOptionLabel}
          key={`to-port-autocomplete-${toPort?.name}`}
          isOptionEqualToValue={handleIsOptionEqualToValue}
          isDisabled={!fromPort?.id}
        />
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button type="submit" disabled>
            Submit
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

export default DestitationForm;